<template>
    <div class="" id="pageTop">
        <div class="row">
            <div class="alert alert-danger col-12" align="center" role="alert" v-if="loginError">
                Invalid email address or password!
            </div>
            <div class="col-12 col-lg-5 p-4 p-lg-5">
                <div class="row">
                    <div class="col-6 h1 my-2 mb-4">
                        <img width="90" src="../assets/logo.png" />
                    </div>
                    <div class="col-6 my-2 mb-4" align="right">
                        <div @click="$router.push('/')" class="pointer"><i class="bi bi-chevron-left"></i> Go back</div>
                    </div>
                    <div class="col-12 h1">Welcome.</div>
                    <div class="col-12 text-secondary mb-5">Login to continue.</div>
                    <div class="col-12 my-2">
                        <label>Email address</label>
                        <input type="email" class="form-control" placeholder="" v-model.trim="login.email" />
                    </div>
                    <div class="col-12 my-2">
                        <label>Password</label>
                        <div class="input-group">
                            <input
                                :type="showPassword ? 'text' : 'password'"
                                class="form-control passwordbox"
                                placeholder=""
                                v-model.trim="login.password"
                            />
                            <button
                                class="btn btn-outline-secondary passwordbox"
                                type="button"
                                v-if="!showPassword"
                                @click="showPassword = !showPassword"
                            >
                                <i class="bi bi-eye"></i>
                            </button>
                            <button
                                class="btn btn-outline-secondary passwordbox"
                                type="button"
                                v-if="showPassword"
                                @click="showPassword = !showPassword"
                            >
                                <i class="bi bi-eye-slash"></i>
                            </button>
                        </div>
                        <p align="right">
                            <button class="btn btn-link btn-sm text-reset" @click="$router.push('/forgot-password')">
                                Forgot password
                            </button>
                        </p>
                    </div>
                    <div class="col-12 my-1 d-grid">
                        <button class="btn btn-dark btn-lg" :disabled="!isFormValid || isBusy" @click="btnLoginClick">
                            <span v-show="!isBusy">Log in</span>
                            <div class="spinner-border text-light" role="status" v-show="isBusy">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                    <div class="col-12 my-3">
                        <button class="btn btn-link text-reset" @click="$router.push('/signup')">
                            Not registered yet? Create an account.
                        </button>
                    </div>
                </div>
            </div>
            <div
                class="col-12 col-lg-7 p-0 d-none d-lg-block"
                style="
                    height: 100vh;
                    background-image: url(https://socaislands.com/wp-content/uploads/2019/10/bobmarley.jpg);
                "
            ></div>
        </div>
    </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export default {
    name: "Login",
    components: {},
    computed: {
        isFormValid() {
            if (!this.login.email) return false;
            if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.login.email))
                return false;
            if (!this.login.password) return false;
            return true;
        },
    },
    data() {
        return {
            showPassword: false,
            loginError: false,
            isBusy: false,
            login: {
                email: "",
                password: "",
            },
        };
    },
    methods: {
        btnLoginClick() {
            this.isBusy = true;
            this.loginError = false;
            const auth = getAuth();
            signInWithEmailAndPassword(auth, this.login.email, this.login.password)
                .then((userCredential) => {
                    let user = userCredential.user;
                    this.$store.dispatch("fetchProfile", user);
                    this.isBusy = false;
                    if (typeof this.$route.query.from !== "undefined") {
                        let decoded_uri = decodeURIComponent(this.$route.query.from);
                        this.$router.push(decoded_uri);
                    } else {
                        this.$router.push("/");
                    }
                })
                .catch((error) => {
                    console.log(error.code, error.message);
                    this.isBusy = false;
                    this.loginError = true;
                    window.scrollTo(0, 0);
                });
        },
    },

    mounted() {
        this.$store.commit("setPageTitle", "login");
    },
};
</script>
