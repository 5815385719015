<template>
	<div class="row mb-5 p-1">
		<div class="col-12 col-lg-3">
		</div>
		<div class="col-12 col-lg-6">
			<div class="alert alert-success mt-5" v-if="showSuccessAlert">
				<div class="h4">Password reset email sent.</div>
				<div>An email has to you with instructions on how to reset your password.</div>
				<div class="my-3">
					<buton @click="$router.go(-1)" class="btn btn-link btn-lg text-reset">Return to login screen.</buton>
				</div>
			</div>
			<div class="row" v-if="!showSuccessAlert">
				<div class="col-6 h1 my-3 mb-4">
					<img width="90" src="../assets/logo.png" />
				</div>
				<div class="col-6 my-2 mb-4" align="right">
					<div @click="$router.go(-1)" class="pointer m-3 mt-4">
						<i class="bi bi-chevron-left "></i> Go back
					</div>
				</div>
				<div class="col-12 h4">
					Recover Password
				</div>
				<div class="col-12 mt-2 mb-4">
					Please enter your email address to continue.
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Email address*</label>
					<input type="text" class="form-control"  v-model.trim="email" />
				</div>
				<div class="col-12" v-if="showFailAlert">
					<div class="alert alert-danger">
						<div class="h4">Password reset error</div>
						<div>There was an error processing your request.</div>
					</div>
				</div>
				<div class="col-12 my-3 d-grid ">
					<button class="btn btn-dark btn-lg" :disabled="!isFormValid || isBusy" @click="btnContinueClick">
						<span v-show="!isBusy">Continue</span>
						<div class="spinner-border text-light" role="status" v-show="isBusy">
							<span class="visually-hidden">Loading...</span>
						</div>
					</button>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-3">
		</div>
	</div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
export default {
	name: 'ForgotPassword',
	components: {
		
	},
	computed:{
		isFormValid(){
			if(!this.email) return false
			if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)) return false
			return true
		}
	},
	data(){
		return{
			isBusy: false,
			email: "",
			showSuccessAlert: false,
			showFailAlert: false,
		}
	},
	methods:{
		btnContinueClick(){
			this.isBusy = true;
			const auth = getAuth();
			sendPasswordResetEmail(auth, this.email).then(()=> {
				this.email = ""
				this.showSuccessAlert = true;
				this.showFailAlert = false;
				this.isBusy = false;
			}).catch(()=> {
				this.showFailAlert = true;
				this.showSuccessAlert = false;
				this.isBusy = false;
			})
		}
	},

	mounted(){
		this.$store.commit("setPageTitle", "login");
	}

}
</script>
