<template>
	<div class="row mb-5 p-1">
		<div class="col-12 col-lg-3">
		</div>
		<div class="col-12 col-lg-6">
			<div class="row">
				<div class="col-6 h1 my-3 mb-4">
					<img width="90" src="../assets/logo.png" />
				</div>
				<div class="col-6 my-2 mb-4" align="right">
					<div @click="$router.go(-1)" class="pointer m-3 mt-4">
						<i class="bi bi-chevron-left "></i> Go back
					</div>
				</div>
				<div class="col-12 my-4 mb-5 h4">
					Create Your Soca Islands account to continue.
				</div>
				
				<div class="col-12 my-2">
					<label class="small text-muted">First name*</label>
					<input type="text" class="form-control"  v-model="signup.fname" />
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Last name*</label>
					<input type="text" class="form-control"  v-model="signup.lname" />
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Email address*</label>
					<input type="email" class="form-control"  v-model="contact.email" />
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Phone*</label>
					<input type="number" class="form-control"  v-model="contact.phone" />
	            </div>
				<div class="col-12 my-2">
					<label class="small text-muted">Gender*</label>
					<select class="form-select"  v-model="signup.gender">
						<option value="" selected disabled></option>
						<option>Female</option>
						<option>Male</option>
						<option>Non-Binary</option>
					</select>
				</div>
				<div class="col-12 my-2">
					<div class="row">
						<div class="col-12 small text-secondary">
							Date of birth*
						</div>
						<div class="col-6">
							<label class="small text-muted">Year*</label>
							<select class="form-select"  v-model="dob.year">
								<template v-for="index in 80" :key="index">
									<option>{{ currentYear - (minAge + index) }}</option>
								</template>
							</select>
						</div>
						<div class="col-3">
							<label class="small text-muted">Month*</label>
							<select class="form-select"  v-model="dob.month">
								<template v-for="index in 12" :key="index">
									<option>{{ index }}</option>
								</template>
							</select>
						</div>
						<div class="col-3">
							<label class="small text-muted">Day*</label>
							<select class="form-select"  v-model="dob.day">
								<template v-for="index in 31" :key="index">
									<option>{{ index }}</option>
								</template>
							</select>
						</div>
					</div>
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Country*</label>
					<select class="form-select"  v-model="signup.country">
						<option value="" selected disabled></option>
						<option value="United States of America">United States of America</option>
						<option value="Canada">Canada</option>
						<option value="United Kingdom">United Kingdom</option>
						<option disabled>------</option>
						<option value="Anguilla">Anguilla</option>
						<option value="Antigua & Barbuda">Antigua & Barbuda</option>
						<option value="Argentina">Argentina</option>
						<option value="Aruba">Aruba</option>
						<option value="Australia">Australia</option>
						<option value="Bahamas">Bahamas</option>
						<option value="Barbados">Barbados</option>
						<option value="Belgium">Belgium</option>
						<option value="Belize">Belize</option>
						<option value="Bermuda">Bermuda</option>
						<option value="Bonaire">Bonaire</option>
						<option value="Brazil">Brazil</option>
						<option value="Cayman Islands">Cayman Islands</option>
						<option value="Colombia">Colombia</option>
						<option value="Cuba">Cuba</option>
						<option value="Curaco">Curacao</option>
						<option value="Denmark">Denmark</option>
						<option value="Dominica">Dominica</option>
						<option value="Dominican Republic">Dominican Republic</option>
						<option value="Finland">Finland</option>
						<option value="France">France</option>
						<option value="French Guiana">French Guiana</option>
						<option value="Germany">Germany</option>
						<option value="Ghana">Ghana</option>
						<option value="Greece">Greece</option>
						<option value="Greenland">Greenland</option>
						<option value="Grenada">Grenada</option>
						<option value="Guadeloupe">Guadeloupe</option>
						<option value="Guyana">Guyana</option>
						<option value="Haiti">Haiti</option>
						<option value="Hawaii">Hawaii</option>
						<option value="Honduras">Honduras</option>
						<option value="Hong Kong">Hong Kong</option>
						<option value="Ireland">Ireland</option>
						<option value="Israel">Israel</option>
						<option value="Italy">Italy</option>
						<option value="Jamaica">Jamaica</option>
						<option value="Japan">Japan</option>
						<option value="Korea Sout">Korea South</option>
						<option value="Martinique">Martinique</option>
						<option value="Mexico">Mexico</option>
						<option value="Montserrat">Montserrat</option>
						<option value="Netherlands">Netherlands (Holland, Europe)</option>
						<option value="New Zealand">New Zealand</option>
						<option value="Nicaragua">Nicaragua</option>
						<option value="Nigeria">Nigeria</option>
						<option value="Norway">Norway</option>
						<option value="Portugal">Portugal</option>
						<option value="Puerto Rico">Puerto Rico</option>
						<option value="St Kitts-Nevis">St Kitts-Nevis</option>
						<option value="St Lucia">St Lucia</option>
						<option value="St Maarten">St Maarten</option>
						<option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
						<option value="South Africa">South Africa</option>
						<option value="Spain">Spain</option>
						<option value="Sri Lanka">Sri Lanka</option>
						<option value="Suriname">Suriname</option>
						<option value="Sweden">Sweden</option>
						<option value="Switzerland">Switzerland</option>
						<option value="Trinidad & Tobago">Trinidad & Tobago</option>
						<option value="Turks & Caicos Is">Turks & Caicos Is</option>
						<option value="United Arab Erimates">United Arab Emirates</option>
						<option value="Venezuela">Venezuela</option>
						<option value="Virgin Islands (British)">Virgin Islands (British)</option>
						<option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
						<option disabled>------</option>
						<option>Other</option>
					</select>
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Password*</label>
					<div class="input-group">
						<input :type="showPassword ? 'text' : 'password'" class="form-control passwordbox" v-model="password" />
						<button class="btn btn-outline-secondary passwordbox" type="button" v-if="!showPassword" @click="showPassword = !showPassword">
							 <i class="bi bi-eye"></i>
						 </button>
						 <button class="btn btn-outline-secondary passwordbox" type="button" v-if="showPassword" @click="showPassword = !showPassword">
							 <i class="bi bi-eye-slash"></i>
						 </button>
					</div>
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Confirm password*</label>
					<div class="input-group">
						<input :type="showPassword ? 'text' : 'password'" class="form-control passwordbox"  v-model="confirm_password" />
						<button class="btn btn-outline-secondary passwordbox" type="button" v-if="!showPassword" @click="showPassword = !showPassword">
							 <i class="bi bi-eye"></i>
						 </button>
						 <button class="btn btn-outline-secondary passwordbox" type="button" v-if="showPassword" @click="showPassword = !showPassword">
							 <i class="bi bi-eye-slash"></i>
						 </button>
					</div>
				</div>
				<div class="col-12 my-3">
					<div class="form-check my-2">
						<input class="form-check-input" type="checkbox" v-model="signup.email_consent" id="emailConsent">
						<label class="form-check-label" for="emailConsent">
							I consent to receive emails from Soca Islands*
						</label>
					</div>
					<div class="form-check my-2">
						<input class="form-check-input" type="checkbox" v-model="signup.data_consent" value="" id="dataConsent">
						<label class="form-check-label" for="dataConsent">
							I agree to allow Soca Islands to store and process my personal data*
						</label>
					</div>
					<div class="form-check my-2">
						<input class="form-check-input" type="checkbox" v-model="signup.agree_terms" id="agreeTerms">
						<label class="form-check-label" for="agreeTerms">
							I agree to Soca Islands terms and policies found at <a href="https://socaislands.com/legal-stuff" target="_legal">https://socaislands.com/legal-stuff</a>*
						</label>
					</div>
				</div>
				<div class="col-12" v-if="showFormError">
					<div class="alert alert-danger">
						<div class="h4">Account Creation Error</div>
						<div>{{ formError }}</div>
					</div>
				</div>
				<div class="col-12 my-3 d-grid ">
					<button class="btn btn-dark btn-lg" :disabled="!isFormValid || isBusy" @click="btnContinueClick">
						<span v-show="!isBusy">Continue</span>
						<div class="spinner-border text-light" role="status" v-show="isBusy">
							<span class="visually-hidden">Loading...</span>
						</div>
					</button>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-3">
		</div>
	</div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; 
import { db, collections } from "../firebaseConfig";

export default {
	name: "Signup",
	components: {
		
	},
	computed:{
		isFormValid(){
			const { signup, contact, password, confirm_password } = this;
			if(!signup.fname) return false;
			if(!signup.lname) return false;
			if(!contact.email) return false;
			if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(contact.email)) return false;
			if(!contact.phone) return false;
			if(!signup.gender) return false;
			if(!signup.country) return false;
			if(!password) return false;
			if(password !== confirm_password) return false;
			if(!signup.data_consent) return false;
			if(!signup.email_consent) return false;
			if(!signup.agree_terms) return false;
			return true;
		},
		currentYear(){
			const d = new Date();
			return d.getFullYear();
		},
	},
	data(){
		return{
			minAge: 18,
			showPassword: false,
			isBusy: false,
			isPhoneValid: false,
			showFormError: false,
			formError: "",
			password: "",
			confirm_password: "",
			contact: {
				email: "",
				phone: "",
			},
			dob: {
				year: 2003,
				month: 1,
				day: 1
			},
			signup:{
				fname: "",
				lname: "",
				gender: "",
				country: "",
				data_consent: false,
				email_consent: false,
				agree_terms: false,
				role: "subscriber",
				created_at: Date.now(),
				updated_at: Date.now(),   
			},
		}
	},
	methods:{
		btnContinueClick(){
			const { signup, contact, dob, password } = this;
			this.isBusy = true;
			this.showFormError = false;
			try{
				const auth = getAuth();
				createUserWithEmailAndPassword(auth, contact.email, password)
				.then(async (userCredential) => {
					const user = userCredential.user;
					updateProfile(auth.currentUser, {
						displayName: `${signup.fname} ${signup.lname}`, phoneNumber: `${signup.phone}`, photoURL: "https://example.com/jane-q-user/profile.jpg"
					}).then(() => {
						console.log("User profile updated");
					}).catch((error) => {
						console.log(error);
					});

					//create user profile
					contact.dob = `${dob.year}-${dob.month}-${dob.day}`;
					contact.stripe_id = await await fetch("https://app.socaislands.com/api/stripe/customer/create", {
						method: "POST",
						headers: {
							"Content-Type": "application/json"
						},
						body: JSON.stringify({
							description: 'Created via Soca Islands.',
							email: contact.email,
							name: `${signup.fname} ${signup.lname}`,
						})
					})
					.then(response => response.json())
					.then(data => {
						return data;
					}); 
					signup.birth_year = dob.year;
					const profileRef = doc(db, collections.PROFILES, user.uid);
					const contactRef = doc(db, collections.CONTACTS, user.uid);
					setDoc(profileRef, signup);
					setDoc(contactRef, contact);
					sendEmailVerification(auth.currentUser);

					this.$store.dispatch("fetchProfile", user)
					this.$router.push("/profile")
					//window.location.replace("/profile");

				})
				.catch((error) => {
					this.formError = error.message.replace("Firebase:", "");
					this.showFormError = true;
					this.isBusy = false;
				});
			}catch(error){
				console.log(error);
				this.formError = "We encountered an error while creating your account. Please try again later oe email explore@socaislands.com for support.";
				this.showFormError = true;
				this.isBusy = false;
			}
		},
	},

	mounted(){
		this.$store.commit("setPageTitle", "sign-up");
	}

}
</script>
