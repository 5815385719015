<template>
	<div class="row mb-5 p-1">
		
	</div>
</template>

<script>
import { getAuth, verifyPasswordResetCode, applyActionCode } from "firebase/auth";
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
	name: 'Auth',
	components: {
		
	},
	computed:{

	},
	data(){
		return{

		}
	},
	methods:{
		handleVerifyEmail(auth, actionCode) {
			applyActionCode(auth, actionCode).then(() => {
				toast.success("Email address confirmed.", {
					timeout: 2000
				});

				this.$router.push("/profile");

			}).catch(() => {
				toast.error("Invalid or expired code.", {
					timeout: 2000
				});

				this.$router.push("/profile");
			});
		},
		handleResetPassword(auth, actionCode) {
			verifyPasswordResetCode(auth, actionCode).then((email) => {
				this.$router.push(`/reset-password?email=${email}&actionCode=${actionCode}`);
			}).catch(() => {
				toast.error("Invalid or expired code.", {
					timeout: 2000
				});
				this.$router.push("/forgot-password");
			});
		}
		
	},
	mounted(){
		this.$store.commit("setPageTitle", "login");

		const auth = getAuth();
		const urlSearchParams = new URLSearchParams(window.location.search);
		const { mode, oobCode } = Object.fromEntries(urlSearchParams.entries());	
		
		switch (mode) {
			case 'resetPassword':
				this.handleResetPassword(auth, oobCode);
			break;
			case 'verifyEmail':
				this.handleVerifyEmail(auth, oobCode);
			break;
			default:
				//error
		}
	}

}
</script>
