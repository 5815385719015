<template>
	<div class="row mb-5 p-1">
		<div class="col-12 col-lg-3">
		</div>
		<div class="col-12 col-lg-6">
			<div class="col-12 my-5" v-if="showSuccessAlert">
				<div class="alert alert-success">
					<div class="h4">Account password updated.</div>
					<div>The password for your account has been updated.</div>
					<div><button class="btn btn-link text-reset btn-lg" @click="reloadPage">Login with new password</button></div>
				</div>
			</div>
			<div class="row" v-if="!showSuccessAlert">
				<div class="col-6 h1 my-3 mb-4">
					<img width="90" src="../assets/logo.png" />
				</div>
				<div class="col-6 my-2 mb-4" align="right">
					<div @click="$router.go(-1)" class="pointer m-3 mt-4">
						<i class="bi bi-chevron-left "></i> Go back
					</div>
				</div>
				<div class="col-12 h4">
					Reset Account Password
				</div>
				<div class="col-12 mt-2 mb-4">
					Please enter your new password to continue.
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Email address*</label>
					<input type="text" readonly class="form-control"  v-model="email" />
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">New password*</label>
					<div class="input-group">
						<input  :type="showPassword ? 'text' : 'password'" class="form-control passwordbox"  v-model="new_password" />
					 	<button class="btn btn-outline-secondary passwordbox" type="button" v-if="!showPassword" @click="showPassword = !showPassword">
							 <i class="bi bi-eye"></i>
						 </button>
						 <button class="btn btn-outline-secondary passwordbox" type="button" v-if="showPassword" @click="showPassword = !showPassword">
							 <i class="bi bi-eye-slash"></i>
						 </button>
					</div>
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Confirm new password*</label>
					<div class="input-group">
						<input :type="showPassword ? 'text' : 'password'" class="form-control passwordbox"  v-model="confirm_new_password" />
					 	<button class="btn btn-outline-secondary passwordbox" type="button" v-if="!showPassword" @click="showPassword = !showPassword">
							<i class="bi bi-eye"></i>
						 </button>
						 <button class="btn btn-outline-secondary passwordbox" type="button" v-if="showPassword" @click="showPassword = !showPassword">
							 <i class="bi bi-eye-slash"></i>
						 </button>
					</div>
				</div>
				<div class="col-12" v-if="showFailAlert">
					<div class="alert alert-danger">
						<div class="h4">Password reset error</div>
						<div>There was an error processing your request.</div>
					</div>
				</div>
				<div class="col-12 my-3 d-grid ">
					<button class="btn btn-dark btn-lg" :disabled="!isFormValid || isBusy" @click="btnContinueClick">
						<span v-show="!isBusy">Continue</span>
						<div class="spinner-border text-light" role="status" v-show="isBusy">
							<span class="visually-hidden">Loading...</span>
						</div>
					</button>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-3">
		</div>
	</div>
</template>

<script>
import { getAuth, confirmPasswordReset } from "firebase/auth";
export default {
	name: 'ResetPassword',
	components: {
		
	},
	computed:{
		isFormValid(){
			const { new_password, confirm_new_password } = this;
			if(!new_password) return false
			if(new_password !== confirm_new_password) return false;
			return true
		}
	},
	data(){
		return{
			isBusy: false,
			email: "",
			new_password: "",
			confirm_new_password: "",
			showSuccessAlert: false,
			showFailAlert: false,
			showPassword: false,
		}
	},
	methods:{
		reloadPage(){
			window.location.assign("/profile");
		},
		btnContinueClick(){
			this.isBusy = true;
			const auth = getAuth();
			const urlSearchParams = new URLSearchParams(window.location.search);
			const { actionCode } = Object.fromEntries(urlSearchParams.entries());

			confirmPasswordReset(auth, actionCode, this.new_password).then(() => {
				this.showFailAlert = false;
				this.showSuccessAlert = true;
			}).catch(() => {
				this.showFailAlert = true;
				this.showSuccessAlert = false;
				this.isBusy = false;
			});
		}
	},

	mounted(){
		this.$store.commit("setPageTitle", "login");

		const urlSearchParams = new URLSearchParams(window.location.search);
		const { email } = Object.fromEntries(urlSearchParams.entries());
		this.email = email;
	}

}
</script>
